import React from "react"
import { ImageStyle, TextStyle, View, ViewStyle } from "react-native"
import { AutoImage, Text } from "../../../components"
import { spacing } from "../../../theme"

const MainSam = require("../images/sam.jpg")

export function FoundersProfile() {
  return (
    <>
      <View>
        <Text preset="italicText" tx="profileFounders.text1" style={ABOUT_TEXT} />
        <Text preset="italicText" tx="profileFounders.text2" style={ABOUT_TEXT} />
        <Text preset="italicText" tx="profileFounders.text3" style={ABOUT_TEXT} />
        <Text preset="italicText" tx="profileFounders.text4" style={ABOUT_TEXT} />
        <Text preset="italicText" tx="profileFounders.text5" style={ABOUT_TEXT} />
        <Text preset="italicText" tx="profileFounders.text6" style={ABOUT_TEXT} />
        <Text preset="h2" text="💖" />
      </View>
      {/* SD-961 <View style={ABOUT_CONTENT}>
        <Text preset="h3" tx="profileAboutSam.title" style={ABOUT_TITLE} />
        <View style={ABOUT_IMAGE_WRAPPER}>
          <AutoImage style={ABOUT_IMAGE} source={MainSam} />
        </View>
        <Text preset="default" tx="profileAboutSam.text1" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutSam.text2" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutSam.text3" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutSam.text4" style={ABOUT_TEXT} />
      </View> */}
    </>
  )
}

const ABOUT_CONTENT: ViewStyle = {
  marginTop: spacing[4],
}
const ABOUT_TITLE: TextStyle = {
  marginBottom: spacing[5],
}
const ABOUT_TEXT: TextStyle = {
  marginBottom: spacing[4],
}
const ABOUT_IMAGE_WRAPPER: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  borderRadius: 16,
  overflow: "hidden",
}
const ABOUT_IMAGE: ImageStyle = {
  width: "100%",
}
